import React from 'react';

const Header = () => {
    return (
        <header className="gameHeader">
            <h1>Bread hunter 4</h1>
        </header>
    )
}

export default Header;